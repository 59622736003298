<template>
<div class="Sidebar">
    <div class="subSidebar">
        <div class="logo">
            <img src="/marca/logo.png" alt="" class="img-fluid" style="width: 90px" />
        </div>

        <div class="fichaAdmin d-flex flex-row" style="margin-top: 36px; align-items: center">
            <div class="letra d-flex" style="
            background: #2f2c42;
            height: 45px;
            width: 45px;
            text-align: center;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            color: white;
          ">
                <strong>AD</strong>
            </div>
            <div class="contenido-letra d-flex flex-column" style="width: 70%; padding-left: 10px; text-align:left">
                <div style="font-size: 15px; text-align: left; font-weight: 600" v-if="$store.state.user">
                    {{ $store.state.user.name }}
                </div>
                <div class="">
                    <small style="font-size: 11px;">{{ $store.state.user.type == 'admin'? 'Administrador':'Empleado' }}</small>
                </div>
            </div>
        </div>
        <!-- /////  -->
        <div class="menu" style="text-align: left">
            <ul class="navbar-nav mr-auto" style="margin-top: 54px; padding: 10px 0">
                <li class="item-menu d-flex flex-row" :style="$route.name == 'Dashboard' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Dashboard')">
                    <div style="width: 20%"><i class="fas fa-cube"></i></div>
                    Dashboard
                </li>
                <li class="item-menu d-flex flex-row" :style="$route.name == 'Envios' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Envios')">
                    <div style="width: 20%"><i class="fas fa-paper-plane"></i></div>
                    Envíos
                </li>
                <li class="item-menu d-flex flex-row" :style="$route.name == 'Categorias' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Categorias')">
                    <div style="width: 20%"><i class="fa-regular fa-layer-group"></i></div>
                    Categorías
                </li>

                <li class="item-menu d-flex flex-row" :style="$route.name == 'Plantillas' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Plantillas')">
                    <div style="width: 20%"><i class="fa-solid fa-pen-nib"></i></div>
                    Plantillas
                </li>

                <li class="item-menu d-flex flex-row" :style="$route.name == 'Empleados' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Empleados')">
                    <div style="width: 20%"><i class="fas fa-users"></i></div>
                    Empleados
                </li>

                <li class="item-menu d-flex flex-row" v-if="$store.state?.user?.type == 'admin'" :style="$route.name == 'DynamicValue' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('DynamicValue')">
                    <div style="width: 20%">
                        <i class="fa-sharp fa-solid fa-hashtag"></i>
                    </div>
                    Campos dinámicos
                </li>

                <div v-if="$store.state.user && $store.state.user.type == 'admin'">
                    <hr />
                    <li class="item-menu d-flex flex-row" :style="$route.name == 'Usuarios' ? { color: '#3f51b5' } : {}" @click.prevent="cambiarRuta('Usuarios')">
                        <div style="width: 20%"><i class="fas fa-user-cog"></i></div>
                        Usuarios
                    </li>
                </div>
            </ul>
        </div>

        <div class="bloque d-flex flex-column" style="
          position: absolute;
          bottom: 35px;
          text-align: center;
          justify-content: center;
          align-items: center;
          margin: auto;
          width: 69%;
        ">
            <strong>Powered By</strong>

            <img src="/marca/logounocomaseis.png" alt="" class="img-fluid" style="width: 110px" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        cambiarRuta(ruta) {
            if (this.$route.name != ruta) {
                this.$router.push({ name: ruta });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.Sidebar {
    top: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding: 10px;
    background: white;
    position: fixed;
    left: 0;
    width: 15.5rem;
    border-right: 1px solid #eee;
    height: 100%;
    padding: 2rem 1rem;
    pointer-events: auto;

    .subSidebar {
        max-width: 12.5rem;
    }

    .item-menu {
        margin-bottom: 30px;
        border-radius: 10px;
        padding: 3px 10px;
        background: rgb(255, 255, 255);
        font-weight: 400;
        font-size: 15px;
        cursor: pointer;
    }
}
</style>
