import Vue from 'vue'
import VueRouter from 'vue-router'
import maquetaDashboard from '../Maqueta/Dashboard/index.vue'
import maquetaAuth from '../Maqueta/Auth/index.vue'
import createView from '../Maqueta/Create/index.vue'
import Firmar from '../Maqueta/Firmar/index.vue'

Vue.use(VueRouter)

const routes = [

    //  Rutas para el auth  
    {
        path: '/',
        name: 'maquetaAuth',
        component: maquetaAuth,
        children: [{
            path: '',
            name: 'Login',
            component: () =>
                import ('../views/Auth/Login.vue')
        }]
    },


    {
        // RUTAS PARA EL ADMINISTRADOR
        path: '/dashboard',
        name: 'maquetaDashboard',
        component: maquetaDashboard,
        children: [{
                path: '',
                name: 'Dashboard',
                component: () =>
                    import ('../views/Dashboard.vue')
            },
            {
                path: 'prueba',
                name: 'prueba',
                component: () =>
                    import ('../views/Prueba.vue')
            },

            {
                path: 'plantillas',
                name: 'Plantillas',
                component: () =>
                    import ('../views/Plantillas/index.vue')
            },
            {

                path: 'categorias',
                name: 'Categorias',
                component: () =>
                    import ('../views/Categorias/index.vue')
            },

            {

                path: 'example',
                name: 'Example',
                component: () =>
                    import ('../views/Example/index.vue')
            },
            {

                path: 'empleados',
                name: 'Empleados',
                component: () =>
                    import ('../views/Empleados/index.vue')
            },
            {

                path: 'dynamic-value',
                name: 'DynamicValue',
                component: () =>
                    import ('../views/dynamicValue/index.vue')
            },

            {

                path: 'empleados/import',
                name: 'empleadosImport',
                component: () =>
                    import ('../views/Empleados/import.vue')
            },

            {

                path: 'envios',
                name: 'Envios',
                component: () =>
                    import ('../views/Envios/index.vue')
            },
            {

                path: 'usuarios',
                name: 'Usuarios',
                component: () =>
                    import ('../views/Usuarios/index.vue')
            }
        ]
    },

    // Vistas para las firmas del documento 
    {
        path: '/view',
        name: 'Sign',
        component: Firmar,
        props: true,
        children: [{
                path: 'sign/:empleado/:envio/document',
                name: 'firmarDocumentos',
                props: true,
                component: () =>
                    import ('../views/Firmar/index.vue')
            },
            {
                path: 'sign/:empleado/:envio/firma',
                name: 'FirmaView',
                props: true,
                component: () =>
                    import ('../views/Firmar/firmarView.vue')
            },

        ]
    },


    // Vistas para las creaciones full screen  
    {
        path: '/plantilla',
        name: 'Create',
        component: createView,
        props: true,
        children: [{
                path: 'create/plantillas',
                name: 'plantillaCreate',
                component: () =>
                    import ('../views/Plantillas/create.vue')
            },
            {
                path: 'update/:plantilla/plantillas',
                name: 'updatePlantilla',
                props: true,
                component: () =>
                    import ('../views/Plantillas/update.vue')
            },
            {
                path: '/selet-coordenates-document-word',
                name: 'selectCoordenatesDocumentWord',
                component: () =>  import('../components/Templates/selectCoordenatesWord.vue'),
                props: true,
            },
            {
                path: '/update-selet-coordenates-document-word',
                name: 'updateSelectCoordenatesDocumentWord',
                component: () =>  import('../components/Templates/selectCoordenatesWord.vue'),
                props: true,
            },

            {
                path: 'upload-document',
                props: true,
                component: () =>
                    import ('../views/Plantillas/templatePDF/create.vue'),
                children: [{
                    path: '/',
                    name: 'selectDocument',
                    component: () =>  import('../components/Templates/uploadSelectPDF.vue'),
                    props:true
                },
                {
                    path: '/selet-coordenates-document',
                    name: 'selectCoordenatesDocument',
                    component: () =>  import('../components/Templates/selectCoordenates.vue'),
                    props: true,
                },
                {
                    path: '/update-selet-coordenates-document',
                    name: 'updateSelectCoordenatesDocument',
                    component: () =>  import('../components/Templates/selectCoordenates.vue'),
                    props: true,
                },
                
                ]

            },

        ]
    }


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router