<template>
<div class="Nav">

    <!-- <ModalDocumentos /> -->

    <div class="contenedor" style="height: 100%">
        <div class="notificacion d-flex" style="
          height: 100%;
          align-items: center;
          justify-content: right;
          padding-right: 100px;
          font-size: 22px;
        ">
            <div class="cuerpoIconBell">
                <i class="fas fa-circle" v-if="$store.state.notificacionesActive == true" style="color: #ff0000; font-size: 9px; position: absolute; margin-left: 12px"></i>
                <div class="dropdown">
                    <i class="fa-solid fa-bell" data-toggle="dropdown" aria-expanded="false" @click.prevent="actualizarNotificaciones()"></i>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" v-if="notificaciones">
                        <li v-for="(item, index) in notificaciones" :key="index">
                            <a v-if="item.envio" class="dropdown-item" @click.prevent="verDocumentos(item.envio)" href="#" style="    white-space: inherit;border-bottom: 1px solid #eee;">
                                <div class="titulo">
                                    <strong style="font-size: 13px;">{{ item.titulo }}</strong>
                                </div>
                                <div class="contenido" style="font-size: 15px;">
                                    {{ item.contenido }}
                                </div>
                                <small style="font-size: 11px;">{{ moment(item.created_at).format('DD/MM/YYYY') }}</small>
                            </a>
                        </li>
                    </ul>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" v-else style="padding:20px">
                        <li>
                            <strong>No existen notificaciones</strong>
                        </li>
                    </ul>

                </div>

            </div>

            <i class="fa-solid fa-power-off" style="margin-left: 80px; color: red" @click.prevent="logout()"></i>
        </div>
    </div>

</div>
</template>

<script>
export default {
    mounted() {
        this.listarNotificacion()
    },
    data() {
        return {
            notificaciones: null,
            mostrar: null,
        }
    },
    components: {
    },
    methods: {

        verDocumentos(item) {
            this.$store.state.mostrar = item;
            this.openModal();

        },
        openModal() {
           // eslint-disable-next-line no-undef
            $("#modalViewDocumentosGeneral").modal('show')
        },

        listarNotificacion() {
            const url = this.$base.dominio + '/notificaciones';
             // eslint-disable-next-line no-undef
            axios.post(url).then(res => {
                this.notificaciones = res.data;
            }).catch(e => {
                console.log(e);
            })
        },
        actualizarNotificaciones() {
            localStorage.setItem("notificacionesActive", JSON.stringify(false));
            this.$store.state.notificacionesActive = false;
        },
        logout() {
            this.$store
                .dispatch("destroyToken")
                .then(() => {
                    window.location.href = "/";
                })
                .catch(() => {
                    // eslint-disable-next-line no-undef
                    toastr.error("Error al cerrar la sesión");
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.Nav {
    z-index: 1;
    background: white;
    box-shadow: 2px 0px 2px 1px #eee;
    height: 60px;
    position: fixed;
    top: 0;
    left: 15.5rem;
    right: 0;

    i {
        cursor: pointer;
    }

    .dropdown-menu {
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate3d(-380px, 41px, 25px) !important;
        width: 420px !important;
    }
}
</style>
