<template>
<div class="Nav">
    <div class="contenedor d-flex" style="
        height: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
      ">
        <div class="d-flex flex-row" style="align-items: center">
            <img src="/marca/logo.png" alt="" style="width: 45px" />
            <div v-if="template_send_fast" style="margin-left: 40px; font-weight: 500; color: rgb(65 65 65)">
                Enviar documento 
            </div>
            <div v-else-if="!template_send_fast && $route.name == 'updatePlantilla' || $route.name == 'updateSelectCoordenatesDocument'" style="margin-left: 40px; font-weight: 500; color: rgb(65 65 65)">
                Actualizar plantilla
            </div>
            <div v-else-if="!template_send_fast && $route.name == 'plantillaCreate' || $route.name == 'selectDocument' || $route.name == 'selectCoordenatesDocument'" style="margin-left: 40px; font-weight: 500; color: rgb(65 65 65)">
                Crear nueva plantilla
            </div>
           

        </div>

        <div class="notificacion d-flex" style="
          height: 100%;
          align-items: center;
          justify-content: right;
          font-size: 22px;
        ">
            <!-- <i class="fa-solid fa-bell" style="margin-right: 100px"></i> -->
            <i class="far fa-times" @click="cerrar()" style="font-size: 28px"></i>
        </div>
    </div>
</div>
</template>

<script>
export default {
    mounted(){
        const employee = JSON.parse(this.$route.query.employee || null);
        if(employee && employee != null && employee != 'null'){
        this.template_send_fast = true;
        }
    },
    data(){
        return {
           template_send_fast:false
        }
    },
    methods: {
        cerrar() {
            if(!this.template_send_fast){
                this.$router.push({ name: "Plantillas" });
            }else {
                this.$router.push({ name: "Empleados" });
            }
            
        },
    },
};
</script>

<style lang="scss" scoped>
.Nav {
    background: white;
    height: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 1px 2px 1px #f0f0f2;

    i {
        cursor: pointer;
    }
}
</style>
