<template>
  <div id="app" style="background: rgb(255 255 255); min-height:100vh">
    <Loader  v-if="$store.state.loader == true" />
    <router-view/>

    <ModalGeneral />
  </div>
</template>
<script>
import Loader from './components/Maqueta/Loader.vue';
import ModalGeneral from './components/View/Envios/modalGeneral.vue';
import SocketioService from "./services/socketio.service.js";

  export default {

    created() {
    SocketioService.setupSocketConnection();
  },
  beforeUnmount() {
    SocketioService.disconnect();
  },
  mounted(){
    if (this.$store.state.user) {
      console.log("se activo");
      SocketioService.socket.on("notificacion-global", (msg) => {
        console.log("si mando la funcion")
        localStorage.setItem("notificacionesActive", JSON.stringify(true));
        this.$store.state.notificacionesActive = true;
      });
    } else {
    }
  },


    components:{
      Loader,
      ModalGeneral
    }
  }
</script>
<style>
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
</style>



<style lang="scss">
.modal-header {
  .fa-regular {
    font-size: 27px !important;
    cursor: pointer !important;
  }
}
.swal2-actions{
  display: revert !important;
}
.swal2-confirm{
  float: right !important
}

.bt-d {
  height: 37px;
}

body {
  font-family: "Roboto", sans-serif !important;
}
body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
small,
strong,
label {
  font-family: "Roboto", sans-serif !important;
}


.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}



#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
